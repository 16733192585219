// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_rL d_fm d_bD d_ds";
export var alignLeft = "v_p2 d_fm d_bD d_ds";
export var alignDiscCenter = "v_rM d_fn d_bB d_dt";
export var alignCenter = "v_bM d_fn d_bB d_dt";
export var alignDiscRight = "v_rN d_fp d_bF d_dv";
export var alignRight = "v_p3 d_fp d_bF d_dv";
export var footerContainer = "v_rP d_dT d_bT";
export var footerContainerFull = "v_rQ d_dR d_bT";
export var footerHeader = "v_kb d_kb";
export var footerTextWrapper = "v_rR d_t";
export var footerDisclaimerWrapper = "v_kj d_kj d_ch";
export var badgeWrapper = "v_rS d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "v_rT undefined";
export var footerDisclaimerLeft = "v_rV undefined";
export var verticalTop = "v_rW d_bx d_bG d_bL d_bJ";
export var firstWide = "v_rX";
export var disclaimer = "v_rY d_bx d_bG";
export var socialDisclaimer = "v_rZ";
export var left = "v_r0";
export var wide = "v_r1 d_cw";
export var right = "v_r2 d_bH";
export var line = "v_fh d_fj d_cs";
export var badgeDisclaimer = "v_r3 d_bz d_bM d_bG";
export var badgeContainer = "v_r4 d_bx d_bF d_bM";
export var badge = "v_r5";
export var padding = "v_r6 d_c5";
export var end = "v_r7 d_bF";
export var linkWrapper = "v_r8 d_dy";
export var link = "v_mz d_dy";
export var colWrapper = "v_r9 d_cv";
export var consent = "v_f d_f d_bz d_bM";
export var media = "v_sb d_bv d_dw";
export var itemRight = "v_sc";
export var itemLeft = "v_sd";
export var itemCenter = "v_sf";
export var exceptionWeight = "v_sg B_tf";