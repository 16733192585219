// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "s_q1 d_bB";
export var datasheetRowWrapper = "s_lr d_lr d_bH";
export var datasheetLeftWrapper = "s_q2 d_bx d_bM";
export var datasheetWrapperFull = "s_q3 d_cB";
export var datasheetWrapperFullLeft = "s_pk d_cB d_bx d_bM d_bB d_bR";
export var contentWrapper = "s_mw d_ls";
export var contentWrapperCenter = "s_q4 d_lx";
export var datasheetLeftWrapperCenter = "s_q5 d_lv d_bx d_bB d_bM d_bG";
export var datasheetImageCenterWrapper = "s_lp d_lp d_t d_cs";
export var datasheetRightWrapperCenter = "s_q6 d_lt";
export var datasheetFooter = "s_lw d_lw";
export var alignLeft = "s_p2 d_ds";
export var alignCenter = "s_bM d_dt";
export var alignRight = "s_p3 d_dv";
export var datasheetInnerWrapperNewLeft = "s_q7 d_fc d_M d_X d_bx d_bM d_dg";
export var datasheetInnerWrapperLeft = "s_q8 d_fb d_M d_X d_bx d_bM d_b3 d_dg d_c7";
export var datasheetInnerWrapperRight = "s_q9 d_fc d_M d_X d_bx d_bM d_dg";
export var datasheetRowWrapperReverse = "s_rb d_bH";
export var datasheetSubtitle = "s_rc d_cv";
export var tableRow = "s_rd";
export var cell = "s_rf";
export var tableRowWrapper = "s_rg d_t d_ds";
export var tableHeadingCell = "s_rh";
export var tableHeading = "s_rj";
export var tableRowStriped = "s_rk";
export var tableRowHeading = "s_rl";
export var dataTable = "s_rm d_t";
export var imageWrapper = "s_p8 d_fd d_X";
export var imageWrapperFull = "s_rn d_F d_t d_bc d_X";
export var imageWrapperIcon = "s_rp";
export var titleMargin = "s_rq d_cq";
export var datasheetInnerInnerWrapper = "s_rr d_t";
export var hasLabels = "s_rs";
export var label = "s_rt";
export var SmallSmall = "s_rv B_rv B_sP B_s9";
export var SmallNormal = "s_rw B_rw B_sP B_tb";
export var SmallLarge = "s_rx B_rx B_sP B_s7";