// extracted by mini-css-extract-plugin
export var articleWrapper = "j_m0";
export var articleText = "j_m1 d_ds d_cq";
export var header = "j_m2 d_X d_t";
export var headerImageWrapper = "j_m3 d_bb d_Y d_6 d_5 d_2 d_7 d_bh d_bP";
export var headerGradient = "j_m4 d_bb d_Y d_6 d_5 d_2 d_7";
export var headerGradientOverlay = "j_m5 d_bb d_Y d_6 d_5 d_2 d_7";
export var headerContentWrapper = "j_m6 d_bx d_bM d_bB d_bG d_bj d_X";
export var headerContentWrapperAlt = "j_m7 d_bx d_bM d_bB d_bG d_bj d_X";
export var contentWrapper = "j_mw d_t";
export var dateTag = "j_m8 d_cc d_cp d_dn d_t";
export var icon1 = "j_m9 d_cM";
export var icon2 = "j_nb d_cM d_cG";
export var tagBtn = "j_nc d_bZ d_cB d_dm d_dy";
export var headerText = "j_nd d_t d_cc d_cq d_bT";
export var center = "j_nf d_dt";
export var videoIframeStyle = "j_ng d_d3 d_t d_F d_bw d_bZ d_P";
export var articleImageWrapper = "j_nh d_cq d_X";
export var articleImageWrapperIcon = "j_nj d_cq d_X";
export var articleRow = "j_nk d_bB d_bc";
export var quoteWrapper = "j_gM d_bz d_bM d_cq";
export var quoteBar = "j_nl d_F";
export var quoteText = "j_nm";
export var authorBox = "j_nn d_t";
export var authorRow = "j_np d_bB d_bT d_cw";
export var separator = "j_nq d_t";
export var line = "j_fh d_t d_fh d_cW d_cw";
export var authorImage = "j_ll d_b5 d_X d_t d_cr";
export var authorText = "j_nr d_cf d_cr";
export var masonryImageWrapper = "j_ns";
export var bottomSeparator = "j_nt d_t d_cw";
export var linksWrapper = "j_nv d_cf d_ds";
export var comments = "j_nw d_bB d_cw";
export var sharing = "j_nx d_bx d_bB d_bG";
export var shareText = "j_ny d_t d_dt";
export var icon = "j_nz";
export var text = "j_nB";
export var SubtitleSmall = "j_nC";
export var SubtitleNormal = "j_nD";
export var SubtitleLarge = "j_nF";