// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "F_vF d_bB";
export var storyRowWrapper = "F_ht d_ht d_bH";
export var storyLeftWrapper = "F_vG d_bx d_bM";
export var storyWrapperFull = "F_vH d_cB";
export var storyWrapperFullLeft = "F_pq d_cB d_bx d_bM d_bB";
export var contentWrapper = "F_mw d_hv";
export var storyLeftWrapperCenter = "F_vJ d_hw d_bx d_bB d_bM d_bG";
export var storyRightWrapperCenter = "F_vK d_hB";
export var storyHeader = "F_vL d_hz d_t d_cq";
export var storyHeaderCenter = "F_hy d_hy d_t d_cq d_dt d_bw";
export var storyParagraphCenter = "F_hx d_hx d_bw d_dt";
export var storyBtnWrapper = "F_vM d_d0 d_dZ d_t d_bx d_bD";
export var storyBtnWrapperCenter = "F_hF d_hF d_dZ d_t d_bx d_bB";
export var imageWrapper = "F_p8 d_fd d_X";
export var imageWrapperFull = "F_rn d_t d_F d_bc d_X";