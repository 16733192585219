// extracted by mini-css-extract-plugin
export var customText = "r_qT d_ds d_cq d_cd";
export var videoIframeStyle = "r_ng d_d3 d_t d_F d_bw d_bZ d_P";
export var customImageWrapper = "r_qV d_cq d_cd d_X";
export var customRow = "r_qW d_bB d_bc";
export var quoteWrapper = "r_gM d_bz d_bM d_cq d_cd";
export var quoteBar = "r_nl d_F";
export var masonryImageWrapper = "r_ns";
export var title = "r_qX";
export var Title3Small = "r_qY B_qY B_sP B_sX";
export var Title3Normal = "r_qZ B_qZ B_sP B_sY";
export var Title3Large = "r_q0 B_q0 B_sP B_sZ";