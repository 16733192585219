// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "w_jM d_jM d_cs";
export var galleryMasonryImage = "w_jL d_jL d_t d_bP d_dy";
export var alignLeft = "w_p2 d_fm d_bD d_ds";
export var alignCenter = "w_bM d_fn d_bB d_dt";
export var alignRight = "w_p3 d_fp d_bF d_dv";
export var galleryContainer = "w_sh d_dT";
export var galleryContainerFull = "w_sj d_dR";
export var galleryRowWrapper = "w_sk d_b9";
export var galleryGuttersImage = "w_jP d_jP d_H d_cs";
export var galleryNoGuttersImage = "w_jN d_jN d_H d_cB";
export var galleryTextGutters = "w_jJ d_jJ d_ct";
export var galleryTextNoGutters = "w_jK d_jK d_ct";
export var galleryTextMasonry = "w_sl d_jJ d_ct";
export var galleryImageWrapper = "w_sm d_fd d_X";
export var descText = "w_sn d_jQ d_Y d_7 d_5 d_6 d_l";
export var guttersDesc = "w_sp";