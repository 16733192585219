// extracted by mini-css-extract-plugin
export var lbContainerOuter = "l_pG";
export var lbContainerInner = "l_pH";
export var movingForwards = "l_pJ";
export var movingForwardsOther = "l_pK";
export var movingBackwards = "l_pL";
export var movingBackwardsOther = "l_pM";
export var lbImage = "l_pN";
export var lbOtherImage = "l_pP";
export var prevButton = "l_pQ";
export var nextButton = "l_pR";
export var closing = "l_pS";
export var appear = "l_pT";