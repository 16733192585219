// extracted by mini-css-extract-plugin
export var articleWrapper = "p_m0";
export var searchResult = "p_qd p_m0";
export var line = "p_fh d_fh d_cW";
export var articleTitle = "p_qf d_cn";
export var articleText = "p_m1 d_cq";
export var authorImage = "p_ll d_ll d_b5 d_X d_cr";
export var authorText = "p_nr d_cf d_cr";
export var textCenter = "p_dt d_dt";
export var searchWrapper = "p_qg d_bx d_bM d_dp d_cj d_X";
export var searchInput = "p_qh d_cC d_t d_b2";
export var searchBtn = "p_qj d_bZ d_bz d_bM d_Y d_cB d_dy";
export var text = "p_nB d_cp";
export var elementWrapper = "p_mt d_cj";
export var select = "p_qk d_t d_b2";
export var articleImageWrapper = "p_nh d_cq d_X d_S";
export var articleImageWrapperIcon = "p_nj d_cq d_X d_S";
export var articleImageWrapperColumns = "p_ql d_cq d_ff d_X";
export var contentPadding = "p_qm d_cQ";
export var otherPadding = "p_qn d_cK";
export var paginationWrapper = "p_qp";
export var pagination = "p_qq";
export var disabledPagination = "p_qr";
export var breakMe = "p_qs";
export var activePage = "p_qt";
export var next = "p_qv";
export var pages = "p_qw";
export var searchTitle = "p_qx d_cp";
export var categoryTitle = "p_qy d_cp d_X";
export var searchText = "p_qz d_cr";
export var bold = "p_qB";
export var field = "p_qC";
export var dateTag = "p_m8 d_j d_cp d_dn";
export var icon1 = "p_m9 d_cM";
export var icon2 = "p_nb d_cM d_cG";
export var tagBtn = "p_nc d_bZ d_cB d_dm d_dy";
export var linksWrapper = "p_nv d_cf d_dt";
export var header = "p_m2 d_t d_X";
export var headerImage = "p_qD d_bb d_Y d_6 d_5 d_2 d_7";
export var separator = "p_nq d_cW";
export var resultImage = "p_qF";