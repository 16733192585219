// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sq d_gr d_cq";
export var heroHeaderCenter = "x_gs d_gs d_cq d_dt";
export var heroHeaderRight = "x_gt d_gt d_cq d_dv";
export var heroParagraphLeft = "x_sr d_gn d_ct";
export var heroParagraphCenter = "x_gp d_gp d_ct d_dt";
export var heroParagraphRight = "x_gq d_gq d_ct d_dv";
export var heroBtnWrapperLeft = "x_ss d_d0 d_dZ d_t d_bx d_bD";
export var heroBtnWrapperCenter = "x_st d_d1 d_dZ d_t d_bx d_bB";
export var heroBtnWrapperRight = "x_sv d_d2 d_dZ d_t d_bx d_bF";
export var overlayBtnWrapper = "x_sw d_gm d_Y d_5 d_6 d_7 d_bl d_b9";
export var design4 = "x_sx d_gl d_Y d_5 d_6 d_bl";
export var heroExceptionSmall = "x_sy B_sy";
export var heroExceptionNormal = "x_sz B_sz";
export var heroExceptionLarge = "x_sB B_sB";
export var Title1Small = "x_sC B_sC B_sP B_sQ";
export var Title1Normal = "x_sD B_sD B_sP B_sR";
export var Title1Large = "x_sF B_sF B_sP B_sS";
export var BodySmall = "x_sG B_sG B_sP B_s6";
export var BodyNormal = "x_sH B_sH B_sP B_s7";
export var BodyLarge = "x_sJ B_sJ B_sP B_s8";